import React from "react";
import Plyr from "plyr-react";
import "./styles/plyr.css";
import "./styles/rainbow.css";

function App() {
  return (
    <>
      <div className="flex flex-col h-full place-items-center justify-center text-white bg-gradient-to-br from-gray-600 via-teal-700 to-gray-800">
        <div className="p-2">
          <div className="p-2 bg-gray-700 border-gray-800 rounded-lg border-4 border-solid place-items-center justify-center flex flex-col subpixel-antialiased">
            <h1 className="pb-2 font-sans font-semibold font-italic">
              PPSavani CFE JEE's Student team in action on the occasion of
            </h1>
            <div className="animate-bounce subpixel-antialiased	">
              <h1 className="rainbow_text_animated font-semibold text-3xl">
                Janmastami
              </h1>
            </div>
          </div>
        </div>
        <VideoPlyr />
        <div className="p-2">
          <div className="justify-center place-items-center flex flex-row  rounded-lg p-2">
            <div className="p-3">
              <h1 className="font-semibold font-mono text-xs bg-gradient-to-br from-pink-500 to-blue-400 p-2 rounded">
                Designed with love by Bhalalansh
              </h1>
            </div>
            <div className="p-3">
              <h1 className="font-semibold font-mono text-xs bg-gradient-to-br from-red-500 to-yellow-400 p-2 rounded">
                Co-Produced by Dixit.V
              </h1>
            </div>
          </div>
          <div className="p-3 justify-center flex content-center">
            <h1 className="font-semibold font-mono text-xs bg-gradient-to-br from-green-500 to-blue-400 p-2 rounded">
              And yeah the whole CFE-JEE team.
            </h1>
          </div>
          <div></div>
        </div>
      </div>
    </>
  );
}

function VideoPlyr() {
  return (
    <div className=" flex border-4 border-gray-800 rounded justify-items-center w-auto h-2/3">
      <Plyr
        source={{
          type: "video",
          title: "PPSavani CFE's Engineers in action.",
          poster: "https://i.imgur.com/VV5qcGc.jpg",  
          sources: [
            {
              src: "https://cdn.sql.gg/UB8h7CKbg2_ppJTqaVDuf5aiHJJYs5Su/video_2021-08-29_20-49-43.mp4",
              type: "video/mp4",
            },
          ],
        }}
      />
    </div>
  );
}

export default App;
